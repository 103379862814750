import React, { Suspense, useState } from 'react';
import ScannerPage from 'components/scanner/Scanner';
import Vehicle from 'components/vehicle/Vehicle';
import CertInspection from 'components/certInspection/certInspection';
import { Provider } from 'react-redux';
import store from 'store/store';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { STRING_CONSTANTS } from 'constants/constants';
import { MFPayload } from 'models/MFPayload';

type ActivePage = 'scanner' | 'vehicleSplash' | 'certLanding';

const App: React.FC<MFPayload> = (props) => {
  const [activePage, setActivePage] = useState<ActivePage>('scanner');
  const [certAssistData, setCertAssistData] = useState<{
    locationCode?: string;
  }>({});
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleNavigation = (
    page: ActivePage,
    certAssistData?: { locationCode?: string },
    resetSuccessMessage: boolean = false
  ) => {
    if (resetSuccessMessage) {
      setSuccessMessage(null);
    }
    setActivePage(page);
    if (certAssistData) {
      setCertAssistData(certAssistData);
    }
  };

  return (
    <Provider store={store}>
      <InterstateThemeProvider
        themeName='Interstate'
        applicationName={STRING_CONSTANTS.APP_NAME}
        scopeName='scope of your application'
        themeRegistries={[interstateThemeRegistry]}
      >
        <Suspense fallback={<div>Loading...</div>}>
          {activePage === 'scanner' && (
            <ScannerPage
              {...props}
              onSubmit={(locationCode) =>
                handleNavigation('vehicleSplash', { locationCode })
              }
              successMessage={successMessage}
            />
          )}
          {activePage === 'vehicleSplash' && (
            <Vehicle
              {...props}
              locationCode={certAssistData.locationCode}
              onContinue={() => handleNavigation('certLanding')}
            />
          )}
          {activePage === 'certLanding' && (
            <CertInspection
              {...props}
              locationCode={certAssistData.locationCode}
              onBack={() => handleNavigation('scanner', undefined, true)}
              onSubmitComplete={() => {
                setSuccessMessage(
                  'Your answers have been stored successfully.'
                );
                handleNavigation('scanner');
              }}
            />
          )}
        </Suspense>
      </InterstateThemeProvider>
    </Provider>
  );
};

export default App;
