import axios from 'axios';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Location, LocationState } from 'store/slice/locations/locations.types';
import { STRING_CONSTANTS } from 'constants/constants';
import { getBaseUrl } from 'utils/configLoader';

axios.defaults.withCredentials = true;

const initialState: LocationState = {
  loading: false,
  data: null,
  error: null
};

// Action
export const fetchLocation = createAsyncThunk<
  Location,
  void,
  { rejectValue: string }
>('locations/fetchLocation', async (_, { rejectWithValue }) => {
  axios.defaults.withCredentials = true;
  try {
    const response = await axios.get<Location>(`${getBaseUrl()}/locations`);
    return response.data;
  } catch (error) {
    let errorMessage = STRING_CONSTANTS.UNKNOWN_ERROR;

    if (axios.isAxiosError(error)) {
      if (error.response) {
        errorMessage =
          error.response.status === 404
            ? 'Auction Location not found'
            : 'Failed to fetch auction Location data';
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    return rejectWithValue(errorMessage);
  }
});

// Reducer
const locationSlice = createSlice({
  name: 'Location',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchLocation.fulfilled,
        (state, action: PayloadAction<Location>) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(fetchLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export default locationSlice.reducer;
