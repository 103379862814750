/* LocationPicker is a reusable component that can be used for showing locations in dropdown, 
   it takes options and callback functions for onSelect */

// Interstate
import { Box } from '@interstate/components/Box';
import { LocationAreaIcon } from '@interstate/components/Icons';
import { SearchInput, SearchOption } from '@interstate/components/SearchInput';

// Styles
import 'reusableComponents/locationPicker/LocationPicker.css';

interface LocationPickerProps {
  options: SearchOption[];
  onSelect: (value: string) => void;
}

const LocationPicker: React.FC<LocationPickerProps> = ({
  options,
  onSelect
}: LocationPickerProps) => {
  function handleLocationChange(event: any) {
    const selected = event.target.value;
    if (selected != null) {
      onSelect(selected.label);
    } else {
      onSelect('');
    }
  }

  return (
    <Box className='location-picker'>
      <LocationAreaIcon width={25} height={25} className='location-icon' />
      <SearchInput
        data-testid='scanner_location_select'
        name='location-picker'
        options={options}
        placeholder='Select Auction'
        onChange={handleLocationChange}
      />
    </Box>
  );
};

export default LocationPicker;
