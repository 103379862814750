/* The Vehicle Splash Page displays a preview of the vehicle data by retrieving it from a DDS API call. 
Clicking the Continue button will take the user to the Certification Landing Page. */

// React and Hooks
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Redux and Store
import { RootState } from 'store/store';

// Interstate
import { Card, CardContent, CardHeader } from '@interstate/components/Card';
import { Typography } from '@interstate/components/Typography';
import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';

// Styles
import 'components/vehicle/Vehicle.css';

// Strings
import { STRING_CONSTANTS } from 'constants/constants';
import { saveScanData } from 'services/recentScan/recentScanService';
import { Scan, RecentScan } from 'services/recentScan/recentScan.types';
import { MFPayload } from 'models/MFPayload';

// Function to format the number
const formatNumber = (number: number) => {
  return number.toLocaleString();
};

type VehicleProps = MFPayload & {
  locationCode?: string;
  onContinue: () => void;
};
// Function to format text to title case
const formatToTitleCase = (units: string): string => {
  return units
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const Vehicle: React.FC<VehicleProps> = (props) => {
  const vehicleState = useSelector((state: RootState) => state.vehicle.data);

  const units = vehicleState?.vehicle.units;
  const distance = units ? formatToTitleCase(units) : '';
  const userId = props.userDetails?.userId;
  const { locationCode } = props;

  function createScanData(): string {
    const vehicle = vehicleState?.vehicle;
    if (vehicle && userId) {
      const scan: Scan = {
        userId: userId,
        inspectionStatus: 'NOT_STARTED', //Todo: to be changed later
        locationCode: locationCode,
        consignmentId: vehicle.consignmentId,
        workOrderNumber: vehicle.workOrderNumber,
        vin: vehicle.vin,
        shortDescription: vehicle.shortDescription,
        exteriorNormalizedName: vehicle.exteriorNormalizedName,
        interiorNormalizedName: vehicle.interiorNormalizedName
      };
      return JSON.stringify(scan);
    }
    return '';
  }

  // Save last scanned record to DB on page load.
  useEffect(() => {
    if (vehicleState?.vehicle && userId) {
      const saveRecentScan = async () => {
        try {
          const currentDate = new Date();
          const lastScannedTime = currentDate.toISOString();
          const params: RecentScan = {
            pk: userId,
            consignmentId: vehicleState?.vehicle?.consignmentId!,
            lastScannedTime: lastScannedTime,
            scanData: createScanData()
          };
          await saveScanData(params);
        } catch (err) {
          console.error(err);
        }
      };
      saveRecentScan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleState, userId, locationCode]);

  function handleClickEvent() {
    props.onContinue();
  }

  return (
    <Box className='vehicle-splash-box'>
      {vehicleState ? (
        <Card
          id='splash-card-container'
          data-testid='splash_card_test'
          padding='auto'
          header={
            <CardHeader
              title={'WO #' + vehicleState?.vehicle.workOrderNumber}
              id='splash-card-header'
            />
          }
          content={
            <CardContent id='splash-card-content'>
              <Typography variant={'strong-lg'} data-testid='vehicle-desc'>
                {vehicleState?.vehicle.shortDescription}
              </Typography>
              <Typography
                tag={'pre'}
                variant={'body-md'}
                data-testid='vehicle-vin'
              >
                {vehicleState?.vehicle.vin}
              </Typography>
              <Typography
                tag={'pre'}
                variant={'body-md'}
                data-testid='vehicle-color'
              >
                {vehicleState?.vehicle.interiorNormalizedName}
                {'/'}
                {vehicleState?.vehicle.exteriorNormalizedName}
              </Typography>

              <Typography
                tag={'pre'}
                variant={'body-md'}
                data-testid='vehicle-units'
              >
                {vehicleState?.vehicle?.reading
                  ? formatNumber(vehicleState?.vehicle?.reading)
                  : vehicleState?.vehicle?.reading}{' '}
                {distance}
              </Typography>
            </CardContent>
          }
        />
      ) : (
        <Typography variant={'h1'} data-testid='data_not_available'>
          {' '}
          {STRING_CONSTANTS.DATA_NOT_AVAILABLE}{' '}
        </Typography>
      )}
      {vehicleState && (
        <Button data-testid='vehicle_splash_button' onClick={handleClickEvent}>
          {STRING_CONSTANTS.CONTINUE}
        </Button>
      )}
    </Box>
  );
};

export default Vehicle;
