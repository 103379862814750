import axios from 'axios';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  RecentScanResponse,
  RecentScanState
} from 'store/slice/recentScans/recentScans.types';
import { getBaseUrl } from 'utils/configLoader';

const initialState: RecentScanState = {
  loading: false,
  data: null,
  error: null
};

// Define the async thunk action
export const getRecentScansForUser = createAsyncThunk<
  RecentScanResponse,
  { userId: String }
>('getRecentScansForUser', async (params, { rejectWithValue }) => {
  const { userId } = params;
  try {
    const response = await axios.get<RecentScanResponse>(
      `${getBaseUrl()}/recentScans/${userId}`
    );
    return response.data;
  } catch (error) {
    let errorMessage = 'An unknown error occurred';

    if (axios.isAxiosError(error)) {
      errorMessage =
        error.response?.status === 404
          ? 'List not found'
          : 'Failed to fetch Recent Scan History';
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    return rejectWithValue(errorMessage);
  }
});

const recentScansSlice = createSlice({
  name: 'recentScansSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecentScansForUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getRecentScansForUser.fulfilled,
        (state, action: PayloadAction<RecentScanResponse>) => {
          state.loading = false;
          state.data = action.payload;
        }
      )
      .addCase(getRecentScansForUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export default recentScansSlice.reducer;
