/* Error Message Bar shown to the user when an error occurs */

// Interstate
import { Grid } from '@interstate/components/Grid';
import { Box } from '@interstate/components/Box';
import { InformationCircleIcon } from '@interstate/components/Icons';
import { Typography } from '@interstate/components/Typography';

// Styles
import 'reusableComponents/messages/errorMessageBar/ErrorMessageBar.css';

// Defining the props that the ErrorMessageBar component will accept
interface ErrorMessageBarProps {
  // `message` prop is a string that represents the error message to be displayed
  message: String;
}

// Defining the ErrorMessageBar component as a functional React component
const ErrorMessageBar: React.FC<ErrorMessageBarProps> = ({
  message
}: ErrorMessageBarProps) => {
  return (
    // Container Grid for the entire error message bar
    <Grid container className='error-message-bar-grid-container'>
      <Box className='error-message-bar'>
        <Grid container>
          <Grid>
            <InformationCircleIcon
              width={14}
              height={14}
              className='error-message-bar-icon'
            />
          </Grid>
          <Grid container>
            <Typography
              data-testid='error-message-text'
              variant='h6'
              className='error-message-bar-text'
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

// Exporting the ErrorMessageBar component
export default ErrorMessageBar;
