import axios from 'axios';
import { RecentScan } from './recentScan.types';
import { getBaseUrl } from 'utils/configLoader';

/**
 * Saves a recent scan to DB by sending a POST request to the backend API.
 *
 * @async
 * @function saveScanData
 * @param { RecentScan } - The body of the request containing the details of the recent scan.
 * @throws { Error } - Throws an error if the request fails. If the error is an Axios error,
 *                     it throws an API-specific error with the response message.
 *                     Otherwise, it throws a generic unexpected error.
 */

export const saveScanData = async (params: RecentScan): Promise<{}> => {
  try {
    const response = await axios.post(`${getBaseUrl()}/recentScan`, params);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        `API Error: ${error.response?.data?.message || error.message}`
      );
    } else {
      throw new Error(`Unexpected Error: ${error}`);
    }
  }
};
