/* ConfirmationModal is a reusable modal screen that behaves as a popup on screen larger than 480px 
   and full screen warning on smaller devices. It takes body, header and button 
   text with all their callbacks as props */

import React from 'react';

// Interstate
import { Modal } from '@interstate/components/Modal';
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';
import { Button } from '@interstate/components/Button';

// Styles
import 'reusableComponents/confirmationModal/ConfirmationModal.css';

interface ConfirmationModalProps {
  modalHeader: string;
  modalBody: string;
  confirmationText: string;
  cancelText?: string;
  showModal: boolean;
  onContinue: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  modalHeader,
  modalBody,
  confirmationText,
  cancelText,
  showModal,
  onContinue,
  onCancel
}: ConfirmationModalProps) => {
  return (
    <Modal
      show={showModal}
      data-testid='confirmation-modal-testid'
      header={
        <Box>
          <Typography variant='h3'>{modalHeader}</Typography>
        </Box>
      }
      id='confirmation-modal'
      size='large'
      padding={false}
    >
      <React.Fragment>
        <Box className='modal-body'>
          <Typography variant='body-md'>{modalBody}</Typography>
          <br />
        </Box>
        <Box className='modal-button-container'>
          {!!cancelText && (
            <Button className='modal-button' onClick={onCancel}>
              {cancelText.toUpperCase()}
            </Button>
          )}
          <Button className='modal-button' onClick={onContinue}>
            {confirmationText.toUpperCase()}
          </Button>
        </Box>
      </React.Fragment>
    </Modal>
  );
};

export default ConfirmationModal;
