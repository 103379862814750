import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { Box } from '@interstate/components/Box';
import { RecentScan } from 'store/slice/recentScans/recentScans.types';
import 'reusableComponents/recentScans/RecentScan.css';

interface RecentScanItemProps {
  recentScanItem: RecentScan;
  searchVehicle: Function;
}

const RecentScanItem: React.FC<RecentScanItemProps> = ({
  recentScanItem,
  searchVehicle
}: RecentScanItemProps) => {
  const recentScan = JSON.parse(recentScanItem.scanData);

  return (
    <Box className='recent-scan-item-background'>
      <Grid
        className='recent-item-grid-container'
        onClick={() =>
          searchVehicle({
            location: recentScan?.locationCode,
            workOrder: recentScan?.workOrderNumber
          })
        }
      >
        <Grid>
          <Typography variant='h6' className='recent-scan-item-wo-text'>
            {recentScan?.locationCode} - #{recentScan?.workOrderNumber}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant='h5' className='recent-scan-item-text'>
            {recentScan?.shortDescription}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant='h5' className='recent-scan-item-text'>
            {recentScan?.vin}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant='h5' className='recent-scan-item-text'>
            {recentScan?.exteriorNormalizedName}/
            {recentScan?.interiorNormalizedName}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
export default RecentScanItem;
