import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';
import { STRING_CONSTANTS } from 'constants/constants';
import React, { useEffect, useRef, useState } from 'react';
import './certInspection.css';
import { Interstitial } from '@interstate/components/Interstitial';
import { Typography } from '@interstate/components/Typography';
import { Alert } from '@interstate/components/Alert';
import { MFPayload } from 'models/MFPayload';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ConfirmationModal from 'reusableComponents/confirmationModal/ConfirmationModal';
import {
  fetchInspectionData,
  postInspectionData
} from 'services/inspection/inspectionService';
import {
  CertData,
  QnaData,
  QuestionResponse
} from 'services/inspection/inspection.types';

declare global {
  interface Window {
    QuestionsAndAnnouncements?: any;
  }
}
type CertInspectionProps = MFPayload & {
  onBack: () => void;
  onSubmitComplete: () => void;
  locationCode?: string;
};

const CertInspection: React.FC<CertInspectionProps> = ({
  onBack,
  onSubmitComplete,
  locationCode,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [qaAvailable, setQaAvailable] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const observerRef = useRef<MutationObserver | null>(null);
  const vehicleState = useSelector((state: RootState) => state.vehicle.data);

  const toUpperCase = (value: any): any => {
    return value ? value.toUpperCase() : undefined;
  };

  const [certData, setCertData] = useState<CertData>({
    consignmentId: vehicleState?.vehicle.consignmentId || 'N/A',
    processStatus: '',
    inspection: {
      startTime: new Date().toISOString(),
      endTime: '',
      locationCode: locationCode,
      workOrderNumber: vehicleState?.vehicle.workOrderNumber || 'N/A',
      manheimAccountNumber: vehicleState?.vehicle.manheimAccountNumber || 'N/A',
      vin: vehicleState?.vehicle.vin || 'N/A',
      userId: toUpperCase(props.userDetails?.userId || 'N/A'),
      consignmentHref: vehicleState?.vehicle.consignmentHref || 'N/A',
      unitHref: vehicleState?.vehicle.unitHref || 'N/A',
      qnaData: {
        valid: false,
        prompting: false,
        questionResponses: [],
        announcements: [],
        totalQuestions: 0,
        questionnaireHref: '',
        metaData: []
      }
    }
  });

  const [initialResponses, setInitialResponses] = useState<QuestionResponse[]>(
    []
  );

  const consignmentId = certData.consignmentId;

  const fetchInitialData = async () => {
    if (!consignmentId) {
      console.error('Consignment ID is not available.');
      return;
    }

    try {
      const data = await fetchInspectionData(consignmentId);

      const { inspection, processStatus } = data;

      const parsedInspection =
        typeof inspection === 'string' ? JSON.parse(inspection) : inspection;

      setCertData((prevCertData) => ({
        ...prevCertData,
        processStatus: processStatus,
        inspection: {
          ...prevCertData.inspection,
          qnaData: {
            ...prevCertData.inspection.qnaData
          }
        }
      }));
      setInitialResponses(parsedInspection.qnaData.questionResponses || []);
      renderQuestions(parsedInspection.qnaData.questionResponses);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      renderQuestions([]);
    }
  };

  const startMutationObserver = () => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'childList' &&
          mutation.target instanceof HTMLElement
        ) {
          if (mutation.target.childElementCount > 0) {
            setLoading(false);
            cleanupMutationObserver();
            break;
          }
        }
      }
    });

    const target = document.getElementById(
      'cert-assist-questions-and-announcements-root'
    );
    if (target) {
      observer.observe(target, { childList: true, subtree: true });
      observerRef.current = observer;
    }
  };

  const cleanupMutationObserver = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = null;
    }
  };

  // save
  useEffect(() => {
    const currentResponses = certData.inspection.qnaData.questionResponses;
    const hasResponsesChanged =
      JSON.stringify(currentResponses) !== JSON.stringify(initialResponses);
    setHasChanges(hasResponsesChanged);

    // submit
    const totalQuestions = certData.inspection.qnaData.totalQuestions;
    const allQuestionsAnswered = currentResponses.length === totalQuestions;

    if (certData.processStatus !== 'PARTIAL' && !hasResponsesChanged) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(!allQuestionsAnswered);
    }
    // eslint-disable-next-line
  }, [certData.inspection.qnaData.questionResponses, certData.processStatus]);

  const renderQuestions = (initialQuestionsResponse: QuestionResponse[]) => {
    if (!window.QuestionsAndAnnouncements) {
      console.error('QuestionsAndAnnouncements is not available.');
      setLoading(false);
      setQaAvailable(false);
      return;
    }

    const initialState = {
      announcements: [],
      questionResponses: initialQuestionsResponse
    };

    window.QuestionsAndAnnouncements.renderQuestions({
      onStateChange: (newState: any) => {
        if (loading) {
          setLoading(false);
          setShowButtons(true);
        }

        const questionResponses = newState.questionResponses.map(
          (response: any) => ({
            guid: response.guid,
            answers: response.answers,
            source: response.source,
            values: response.values,
            metadata: {
              updatedBy: toUpperCase(props.userDetails?.userId || 'N/A')
            }
          })
        );

        const qnaData: QnaData = {
          valid: newState.valid,
          prompting: newState.prompting,
          questionResponses,
          announcements: newState.announcements || [],
          totalQuestions: newState.totalQuestions,
          questionnaireHref: newState.questionnaireHref || '',
          metaData: newState.metaData || []
        };

        setCertData((prevCertData) => ({
          ...prevCertData,
          inspection: {
            ...prevCertData.inspection,
            qnaData
          }
        }));
        setShowAlert(false);
      },
      clientApplicationName: STRING_CONSTANTS.APP_NAME,
      rootElementId: 'cert-assist-questions-and-announcements-root',
      environment: props.appConfig?.qnaEnvironment,
      announcerSource: STRING_CONSTANTS.QE_ANNOUNCER_SOURCE,
      questionnaireHref: props.appConfig?.certFormUrl,
      initialState: initialState
    });
  };

  useEffect(() => {
    fetchInitialData();
    startMutationObserver();
    return cleanupMutationObserver;
    // eslint-disable-next-line
  }, []);

  const postUpdatedCertData = async (status: string) => {
    setLoading(true);
    const endTime = new Date().toISOString();

    const updatedInspection = {
      ...certData.inspection,
      endTime: endTime
    };

    const updatedCertData = {
      ...certData,
      processStatus: status,
      inspection: JSON.stringify(updatedInspection)
    };

    try {
      await postInspectionData(updatedCertData);
      setHasChanges(false);
      setShowAlert(false);
      return true;
    } catch (error) {
      console.error('Error posting updated certData:', error);
      setAlertMessage('Failed to store answers. Please try again.');
      setShowAlert(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const postAndNavigate = async (status: string) => {
    const isSaved = await postUpdatedCertData(status);
    if (isSaved) {
      onSubmitComplete();
    }
  };

  const handleSave = () => {
    postAndNavigate('PARTIAL');
  };

  const handleSubmit = () => {
    postAndNavigate('SUBMITTED');
  };

  const handleCancel = () => {
    if (hasChanges) {
      setShowModal(true);
    } else {
      onBack();
    }
  };

  const handleModalYesButtonAction = () => {
    onBack();
  };

  const handleModalNoButtonAction = () => {
    setShowModal(false);
  };

  const isSubmitButtonDisabled = () => {
    return isSubmitDisabled;
  };

  return (
    <Box>
      <Box id='cert-assist-questions-and-announcements-root'></Box>
      {loading && (
        <Interstitial
          data-testid='loading'
          fullScreen={true}
          size={2}
          message={showButtons ? 'Storing answers' : 'Loading Questions'}
        />
      )}
      {showAlert && (
        <Alert title='Error' type='error' className='alert-error'>
          <Typography variant='body-md'>{alertMessage}</Typography>
        </Alert>
      )}
      {!qaAvailable ? (
        <Typography
          variant={'h1'}
          data-testid='qe_not_available'
          className='error_card'
        >
          {STRING_CONSTANTS.QE_NOT_AVAILABLE}
        </Typography>
      ) : showButtons ? (
        <Box className='cert-buttons' component={'div'}>
          <Button onClick={handleSave} disabled={!hasChanges}>
            {STRING_CONSTANTS.SAVE}
          </Button>
          <Button onClick={handleSubmit} disabled={isSubmitButtonDisabled()}>
            {STRING_CONSTANTS.SUBMIT}
          </Button>
          <Button onClick={handleCancel}>{STRING_CONSTANTS.CANCEL}</Button>
        </Box>
      ) : null}
      <ConfirmationModal
        modalHeader={STRING_CONSTANTS.EXIT_BEFORE_SAVING}
        modalBody={STRING_CONSTANTS.CERTIFICATION_EXIT_WARNING}
        confirmationText={STRING_CONSTANTS.YES}
        cancelText={STRING_CONSTANTS.NO}
        showModal={showModal}
        onContinue={handleModalYesButtonAction}
        onCancel={handleModalNoButtonAction}
      />
    </Box>
  );
};

export default CertInspection;
